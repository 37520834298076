import React from 'react';

function Serviceslist({data}) {
    return (
        <>

        </>
    );
}

export default Serviceslist;